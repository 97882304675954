.layout-config {
    transition: transform $transitionDuration;

    .p-sidebar-content,
    .layout-config-panel {
        height: 100%;
    }

    .p-sidebar-header,
    .p-sidebar-content {
        padding: 0;
    }

    .layout-config-options {
        height: 100%;
        overflow-y: auto;

        .layout-config-color-option {
            width: 1.75rem;
            height: 1.75rem;
            display: block;
            position: relative;

            &.p-disabled {
                opacity: .1;
            }

            .color {
                display: inline-block;
                width: 100%;
                height: 100%;
                border-radius: 4px;
                border: 1px solid $dividerColor;
            }

            .check {
                position: absolute;
                left: 0;
                top: 0;
                z-index: 1;
                pointer-events: none;
                width: 100%;
                height: 100%;
            }
        }
    }

    p {
        color: var(--text-color-secondary);
    }

    .scale-icon {
        color: var(--surface-d);

        &.scale-active {
            color: var(--primary-color);
        }
    }
}

.layout-config-button.p-button {
    position: fixed;
    top: 35%;
    right: 0;
    width: auto;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    .p-button-icon {
        font-size: 2rem;
    }
}
