.layout-topbar-orange {
    $topbarBgColor: #FB8C00;
    $topbarLeftBgColor: #EF6C00;

    $topbarMenuButtonBgColor:#212121;
    $topbarMenuButtonTextColor:#ffffff;

    $topbarItemTextColor: #212121;
    $topbarItemTextHoverBgColor: rgba(0,0,0,.12);

    @import '../_topbar_exports';
    @import '../_topbar_theme';
}
