.layout-wrapper {
    &.layout-rtl {
        direction: rtl;

        .menu-wrapper {
            left: auto;
            right: 0;

            .layout-menu-container {
                .layout-menu {
                    li {
                        &.layout-root-menuitem {
                            > div {
                                padding-left: 0;
                                padding-right: 1rem;
                            }
                        }

                        > a {
                            .p-badge,
                            .layout-submenu-toggler {
                                margin-left: 0;
                                margin-right: auto;
                            }

                            .layout-menuitem-text {
                                padding-right: 1.2rem;
                                margin-right: -7px;
                                margin-left: -7px;
                                border-bottom: 1px solid #00000029;
                                height: inherit;
                                width: calc(100% + 20px);
                                padding-top: 9px;
                            }
                        }

                        > ul {
                            > li {
                                @include nested-submenu-indents-rtl(0.875rem, 2, 5);
                            }
                        }
                    }
                }
            }

            .layout-inline-menu {
                .layout-inline-menu-action-panel {
                    .layout-inline-menu-action-item {
                        a {
                            i {
                                margin-right: 0;
                                margin-left: 0.75rem;
                            }
                        }
                    }
                }
            }
        }

        .layout-topbar .layout-topbar-left .layout-menu-button > i {
            transform: rotate(180deg);
        }

        @media (min-width: $mobileBreakpoint + 1) {
            .menu-wrapper {
                left: auto;
                right: 5.2rem;
            }
            &.layout-menu-horizontal {
                .menu-wrapper {
                    .layout-menu-container {
                        .layout-menu {
                            > li {
                                > ul {
                                    left: auto;
                                    right: 0;
                                }
                            }
                        }
                    }
                }
            }

            &.layout-menu-overlay {
                .menu-wrapper {
                    transform: translate3d(19rem, 0px, 0px);
                }

                &.layout-menu-active {
                    .menu-wrapper {
                        transform: translate3d(0px, 0px, 0px);
                    }

                    .layout-topbar .layout-topbar-left .layout-menu-button > i {
                        transform: rotate(0deg);
                    }
                }
            }

            &.layout-menu-slim {
                .menu-wrapper {
                    .layout-menu-container {
                        .layout-menu {
                            > li {
                                > ul {
                                    left: auto;
                                    right: 4.5rem;
                                }
                            }
                        }
                    }
                }

                &.layout-wrapper {
                    .layout-main {
                        margin-left: 0;
                        margin-right: 4.5rem;
                    }
                }
            }

            &.layout-menu-static {
                .menu-wrapper {
                    transform: translate3d(19rem, 0px, 0px);
                }

                .layout-main {
                    transition: margin-right $transitionDuration;
                }

                &.layout-menu-active {
                    .menu-wrapper {
                        position: fixed;
                        top: 12vh;
                        left: 0;
                        width: 250px;
                        height: 85vh;
                        background-color: #fff;
                        transition: transform 0.3s ease;
                        transform: translateX(-23px);
                        border-radius:24px 0px  0 24px;
                    }

                    .layout-topbar .layout-topbar-left .layout-menu-button > i {
                        transform: rotate(0deg);
                    }

                    .layout-main {
                        margin-left: 0;
                        /// margin-right: 16.25rem;
                    }
                }
            }
        }

        @media (max-width: $mobileBreakpoint) {
            .menu-wrapper {
                transform: translate3d(19rem, 0px, 0px);
            }

            &.layout-menu-mobile-active {
                .menu-wrapper {
                    transform: translate3d(0px, 0px, 0px);
                }

                .layout-topbar .layout-topbar-left .layout-menu-button > i {
                    transform: rotate(0deg);
                }
            }
        }
    }
}
