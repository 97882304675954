.pages-body {
    height: 100vh;

    .topbar {
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
        background-color: #1565C0;
        z-index: 100;

        .topbar-left {
            img {
                height: 2rem;
            }
        }

        .p-button.p-button-text.p-button-plain {
            color: #ffffff;
        }
    }

    .pages-panel {
        text-align: center;
        z-index: 200;

        &.card {
            img {
                width: 100%;
            }

            border: 1.5px solid $dividerColor;
            border-radius: 6px;

            >.card {
                background-color: $contentAltBgColor;
            }
        }

        .pages-header {
            margin-top: -3rem;
            color: var(--primary-color-text);
            border: 1.5px solid $dividerColor;
            border-radius: 4px;
            margin-left: auto;
            margin-right: auto;

            h2 {
                margin: 0;
            }
        }

        .pages-detail {
            color: $textSecondaryColor;
        }
    }

    &.login-page {
        //background: url("../images/pages/login-bg.jpg");
        background-size: cover;

        .pages-panel {
            .pages-header {
                background: #0097A7;
            }

            .input-panel {
                width: 100%;

                .p-inputtext {
                    width: 100%
                }
            }

            .login-button > * {
                width: 100%;
            }
        }
    }

    &.contact-page {

        .map {
            iframe {
                border: 0;
                position: absolute;
                float: left;
                clear: both;
                width: 100%;
                height: 45vh;
                z-index: 0;
                opacity: 0.5;
            }

            span {
                position: absolute;
                float: left;
                clear: both;
                width: 100%;
                height: 45vh;
                z-index: -1;
                background: linear-gradient(180deg, #040B0F 0%, rgba(7, 14, 18, 0) 100%);
            }
        }

        i {
            color: white;
        }

        .pages-panel {
            text-align: left;
            width: 75%;
            max-width: 1050px;
            margin: auto;
            position: absolute;
            top: 33%;
            left: 0; right: 0;

            .title {
                font-weight: 500;
                margin-bottom: 0;
            }

            .card {
                .contact-input {
                    width: 100%;
                }

                .right-panel {

                    i {
                        font-size: 2rem;
                        padding: 0.5rem;
                        border-radius: 4px;

                        &.pi-home {
                            color: #1565C0;
                            background: #BBDEFB;
                            background-size: auto;
                        }

                        &.pi-briefcase {
                            color: #00838f;
                            background: #B2DFDB;
                            background-size: auto;
                        }
                    }
                }
            }
        }
    }

    &.error-page {
       // background: url("../images/pages/error-bg.jpg");
        background-size: cover;

        .pages-panel {
            .pages-header {
                background: #D81B60;
            }
        }
    }

    &.notfound-page {
        //background: url("../images/pages/404-bg.jpg");
        background-size: cover;

        .pages-panel {
            .pages-header {
                background: #455A64;
            }
        }
    }

    &.accessdenied-page {
       // background: url("../images/pages/accessDenied-bg.jpg");
        background-size: cover;

        .pages-panel {
            .pages-header {
                background: #FB8C00;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .pages-body {
        &.contact-page {
            .pages-panel {
                padding-bottom: 10em;

                .p-button {
                    width: 100%;
                }

                .card {
                    .right-panel {
                        text-align: center;
                    }
                }
            }
        }
    }
}
