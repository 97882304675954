.layout-topbar-deeppurple {
    $topbarBgColor: #4527A0;
    $topbarLeftBgColor: #311B92;

    $topbarMenuButtonBgColor:#F9A825;
    $topbarMenuButtonTextColor:#212121;

    $topbarItemTextColor: #ffffff;
    $topbarItemTextHoverBgColor: rgba(255,255,255,.12);

    @import '../_topbar_exports';
    @import '../_topbar_theme';
}
