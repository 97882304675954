.layout-topbar-white {
    $topbarBgColor: #ffffff;
    $topbarLeftBgColor: #ffffff;

    $topbarMenuButtonBgColor:#ffffff;
    $topbarMenuButtonTextColor:#212121;

    $topbarItemTextColor: #616161;
    $topbarItemTextHoverBgColor: rgba(0,0,0,.12);

    @import '../_topbar_exports';
    @import '../_topbar_theme';
}
