@media (min-width: $mobileBreakpoint + 1) {
    .layout-menu-horizontal {
        .menu-wrapper {
            width: 100%;
            height: 3rem;

            .layout-menu-container {
                flex-direction: row;

                app-menu {
                    overflow-y: visible;
                    flex-grow: 1;
                }

                .layout-menu {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    height: 100%;
                    margin: 0px 1rem;
                    padding-bottom: 0px;

                    > li {
                        position: relative;

                        > a {
                            margin-right: 1rem;
                            display: flex;
                            align-items: center;
                        }

                        &.layout-root-menuitem {
                            > div {
                                display: none;
                            }
                        }

                        > ul {
                            display: none;
                            position: absolute;
                            top: 2.75rem;
                            left: 0px;
                            z-index: 100;
                            padding: .5rem;
                            overflow: auto;
                            max-height: 32rem;
                            min-width: 16rem;
                            border-radius: $borderRadius;
                        }

                        &.active-menuitem {
                            > ul {
                                display: block;
                            }
                        }
                    }
                }

                .layout-inline-menu {
                    border: 0 none;

                    > .layout-inline-menu-action {
                        height: 3rem;
                    }
                }
            }
        }

        &.layout-wrapper {
            .layout-main {
                padding-top: 7rem;
            }
        }
    }
}
