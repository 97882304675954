.layout-topbar-teal {
    $topbarBgColor: #00796B;
    $topbarLeftBgColor: #004D40;

    $topbarMenuButtonBgColor:#D32F2F;
    $topbarMenuButtonTextColor:#ffffff;

    $topbarItemTextColor: #ffffff;
    $topbarItemTextHoverBgColor: rgba(255,255,255,.12);

    @import '../_topbar_exports';
    @import '../_topbar_theme';
}
