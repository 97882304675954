.landing-container {
    .landing-color-button {
        position: fixed;
        bottom: 3rem;
        right: 3rem;
        z-index: 9999;
    }

    h1, h2, h3, h4, h5, h6 {
        font-weight: 400;
    }

    ul {
        @include reset-list;
    }

    .section {
        padding: 2rem 15rem;
    }

    #header {
        width: 100%;
        min-height: 400px;
        background: url("../../layout/images/landing/landing-header.png") top left no-repeat #f7f7f7;
        background-size: cover;

        .header-menu-container {
            a {
                color: #ffffff;
                cursor: pointer;
            }

            #menu {
                display: flex;
                align-items: center;
                flex-direction: row;
            }
        }

        .header-text {
            flex-grow: 1;
        }
    }

    #features {
        .feature-button {
            width: 100px;
            height: 100px;
        }
    }

    #promotion {
        background: url("../../layout/images/landing/promotion.png") top left no-repeat;
        background-size: 100% auto;
    }

    #pricing {
        .pricing-content {
            width: 100%;

            .card {
                height: 100%;
            }

            .options {
                padding: 2rem 4rem;

                li {
                    display: flex;
                    align-items: center;
                }

                i, span {
                    padding: 1rem;
                }

                span {
                    font-size: 1.2rem;
                }
            }
        }
    }

    #footer {
        border-top: 1px solid $dividerColor;

        a {
            color: $textColor;
        }

        li {
            padding: .25rem;
        }
    }
}

@media screen and (min-width: 990px) {
    .landing-container {
        #header {
            .header-menu-container {
                box-shadow: none;

                #menu {
                    box-shadow: none;
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .landing-container {
        .section {
            padding: 2rem;
        }

        #header {
            .header-menu-container {
                z-index: 100;
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                background: #212121;
                padding: 2rem 5rem;

                #menu {
                    display: none;
                    z-index: 100;
                    position: fixed;
                    top: 6rem;
                    right: 5rem;
                    width: 250px;
                    background-color: $contentBgColor;
                    color: $textColor;
                    animation-duration: $transitionDuration;

                    a {
                        color: $textColor;
                    }

                    > li {
                        width: 100%;

                        &:hover {
                            background-color: $hoverBgColor;
                            transition: background-color $transitionDuration;
                        }
                    }

                    &.menu-active {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: stretch;
                    }
                }
            }
        }
    }
}
