.layout-topbar-blue {
    $topbarBgColor: #021f4e;
    $topbarLeftBgColor: #0D47A1;

    $topbarMenuButtonBgColor:#FBC02D;
    $topbarMenuButtonTextColor:white;

    $topbarItemTextColor: #fffafa;
    $topbarItemTextHoverBgColor: rgba(255,255,255,.12);

    @import '../_topbar_exports';
    @import '../_topbar_theme';
}
