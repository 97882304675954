/* You can add global styles to this file, and also import other style files */
.top-radius {
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
}
.bottom-radius {
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
}
.bg-light-blue {
    background-color: rgba(167, 223, 248, 1);
}
.bg-light-blue-30 {
    background-color: rgba(167, 223, 248, 0.3);
}
.bg-light-blue-50 {
    background-color: rgba(167, 223, 248, 0.5);
}
.border-light-blue {
    border-color: rgba(167, 223, 248, 1);
}

/* Firefox (uncomment to work in Firefox, although other properties will not work!)  */

/** {

  scrollbar-width: thin;

  scrollbar-color: #030F6D #DFE9EB;

}*/

/* Chrome, Edge and Safari */

*::-webkit-scrollbar {
    height: 8px;
    width: 8px;
}

*::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: #dee2e6;
}

*::-webkit-scrollbar-track:hover {
    background-color: #dee2e6;
}

*::-webkit-scrollbar-track:active {
    background-color: #dee2e6;
}

*::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: var(--bluColorA);
}

*::-webkit-scrollbar-thumb:hover {
    background-color: var(--bluColorA);
}

*::-webkit-scrollbar-thumb:active {
    background-color: var(--bluColorA);
}
p-chips::after {
    padding-right: 5px;
    content: 'יש ללחוץ אנטר אחרי כל ערך.';
    display: block;
    font-size: 0.875rem;
    margin-top: 5px;
  }