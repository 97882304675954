.layout-menu-purple {
    $menuBgColor:#6A1B9A;

    $rootMenuTextColor:#ffffff;

    $menuItemTextColor:rgba(255,255,255,.6);
    $menuItemHoverBgColor:rgba(255,255,255,.12);
    $activeMenuItemTextColor:#ffffff;
    $activeMenuItemBgColor: rgba(255,255,255,.24);

    $inlineMenuBorderColor: rgba(255,255,255,.24);

    @import '../_menu_exports';
    @import '../_menu_theme';
}

