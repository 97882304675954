@keyframes overlayEnter {
    from {
        opacity: 0;
        transform: scaleY(0.8);
    }

    to {
        opacity: 1;
        transform: none;
    }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
}

@keyframes fadeinmask {
    from { opacity: 0; }
    to   { opacity: .8; }
}

.fadeInDown {
    animation-name: fadeInDown;
}

.fadeOutUp {
    animation-name: fadeOutUp;
}

@keyframes modal-in {
  from {
      background-color: transparent;
    }

    to {
        background-color: rgba(0,0,0,0.6);
    }
}

.modal-in {
  animation-name: modal-in;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}
