
@font-face {
    font-family: Assistant;
    src: url(../../../fonts/Assistant/static/Assistant-Regular.ttf) format("opentype");
}

//general
$fontSize:16px !default;
//$fontFamily: 'Roboto' !default;
$fontFamily: Assistant, tahoma, arial, sans-serif;
$borderRadius:5px !default;
$animationDuration:.2s !default;
$animationTimingFunction:cubic-bezier(.05,.74,.2,.99) !default;
$letterSpacing:normal !default;
$transitionDuration:.2s !default;
$mobileBreakpoint:991px !default;
