$colors: (
    "blue": #2196F3,
    "green": #689F38,
    "yellow": #FBC02D,
    "cyan": #00BCD4,
    "pink": #E91E63,
    "indigo": #4E5FBB,
    "teal": #009688,
    "orange": #FF9800,
    "bluegray": #607D8B,
    "purple": #9C27B0
);

$orangeColor: #FF6700;
$bluColorA: #030F6D;
$bluColorB: #2CA5DB;
$lightBluColorA: #CAE8FC;
$lightBluColorB: #EFF9FE;
$black: #000000;
$white: #ffffff;







$whiteColor: white;
$dominantColor: $bluColorA;
$dominantColor2: $lightBluColorA;
$dominantColor3: $orangeColor;
$dominantBackground: $bluColorA;

$emphasis-high: rgba(0, 0, 0, .87);
$emphasis-medium: rgba(0, 0, 0, .60);
$emphasis-low: rgba(0, 0, 0, .38);
$emphasis-lower: rgba(0, 0, 0, .12);
$overlayColor: #000000;

//global
//$fontFamily:Roboto,Helvetica Neue Light,Helvetica Neue,Helvetica,Arial,Lucida Grande,sans-serif;
$fontFamily: Assistant, tahoma, arial, sans-serif;

$fontSize: 1rem;
$fontWeight: normal;
//$textColor:$emphasis-high;
$textColor: $dominantColor;
//$textSecondaryColor:$emphasis-medium;
$textSecondaryColor: $dominantColor;
$textWhiteColor: $whiteColor;
$borderRadius: 4px !default;
$transitionDuration: .2s;
$formElementTransition: background-color $transitionDuration, border-color $transitionDuration, color $transitionDuration, box-shadow $transitionDuration, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
$actionIconTransition: background-color $transitionDuration, color $transitionDuration, box-shadow $transitionDuration;
$listItemTransition: none;
$primeIconFontSize: 1rem;
$divider: 1px solid rgba(0, 0, 0, .12);
$inlineSpacing: .5rem;
$disabledOpacity: .38;
$maskBg: rgba(0, 0, 0, 0.32);
$loadingIconFontSize: 2rem;
$errorColor: #B00020;

//selected state
$highlightBg: rgba($primaryColor, .12);
$highlightTextColor: $primaryColor;

//scale
$scaleSM: 0.875;
$scaleLG: 1.25;

//focus
$focusOutlineColor: transparent;
$focusOutline: 0 none;
$focusOutlineOffset: 0;
$focusShadow: none;

//action icons
$actionIconWidth: 2.5rem;
$actionIconHeight: 2.5rem;
$actionIconBg: transparent;
$actionIconBorder: 0 none;
$actionIconColor: $textSecondaryColor;
$actionIconHoverBg: rgba(0, 0, 0, .04);
$actionIconHoverBorderColor: transparent;
$actionIconHoverColor: $textSecondaryColor;
$actionIconBorderRadius: 50%;

//input field (e.g. inputtext, spinner, inputmask)
$inputPadding: 1rem 1rem;
$inputTextFontSize: 1rem;
$inputBg: #ffffff;
//$inputTextColor:$emphasis-high;
$inputTextColor: $dominantColor;

//$inputIconColor:$emphasis-medium;
$inputIconColor: $dominantColor;
//$inputBorder:1px solid $emphasis-low;
$inputBorder: 1px solid $dominantColor;

$inputHoverBorderColor: $emphasis-high;
$inputFocusBorderColor: $primaryColor;
$inputErrorBorderColor: $errorColor;
//$inputPlaceholderTextColor:$emphasis-medium;
$inputPlaceholderTextColor: $dominantColor;

//$inputFilledBg:#f5f5f5;
$inputFilledBg: #ffffff;

$inputFilledHoverBg: #ececec;
$inputFilledFocusBg: #dcdcdc;

//input groups
$inputGroupBg: $inputBg;
$inputGroupTextColor: $emphasis-medium;
$inputGroupAddOnMinWidth: 2.357rem;

//input lists (e.g. dropdown, autocomplete, multiselect, orderlist)
$inputListBg: #ffffff;
$inputListTextColor: $textColor;
$inputListBorder: $inputBorder;
$inputListPadding: 0;
$inputListItemPadding: 1rem 1rem;
$inputListItemBg: transparent;
$inputListItemTextColor: $textColor;
$inputListItemHoverBg: rgba(0, 0, 0, .04);
$inputListItemTextHoverColor: $textColor;
$inputListItemBorder: 0 none;
$inputListItemBorderRadius: 0;
$inputListItemMargin: 0;
$inputListItemFocusShadow: none;
$inputListHeaderPadding: 1rem;
$inputListHeaderMargin: 0;
$inputListHeaderBg: #ffffff;
$inputListHeaderTextColor: $textColor;
$inputListHeaderBorder: 1px solid rgba(0, 0, 0, .12);

//inputs with overlays (e.g. autocomplete, dropdown, multiselect)
$inputOverlayBg: $inputListBg;
$inputOverlayHeaderBg: $inputListHeaderBg;
$inputOverlayBorder: 0 none;
$inputOverlayShadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);

//password
$passwordMeterBg: rgba($primaryColor, .32);
$passwordWeakBg: #D32F2F;
$passwordMediumBg: #FBC02D;
$passwordStrongBg: #689F38;

//button
$buttonPadding: 0.714rem 1rem;
$buttonIconOnlyWidth: 3rem;
$buttonIconOnlyPadding: 0.714rem;
$buttonBg: $primaryColor;
$buttonTextColor: $primaryTextColor;
$buttonBorder: 0 none;
$buttonHoverBg: rgba($primaryColor, .92);
$buttonTextHoverColor: $primaryTextColor;
$buttonHoverBorderColor: transparent;
$buttonActiveBg: rgba($primaryColor, .68);
$buttonTextActiveColor: $primaryTextColor;
$buttonActiveBorderColor: transparent;
$raisedButtonShadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
$roundedButtonBorderRadius: 2rem;

$textButtonHoverBgOpacity: .04;
$textButtonActiveBgOpacity: .16;
$outlinedButtonBorder: 0 none;
$plainButtonTextColor: $textSecondaryColor;
$plainButtonHoverBgColor: rgba(0, 0, 0, .04);
$plainButtonActiveBgColor: rgba(0, 0, 0, .16);

$secondaryButtonBg: $accentColor;
$secondaryButtonTextColor: $accentTextColor;
$secondaryButtonBorder: 0 none;
$secondaryButtonHoverBg: rgba($accentColor, .92);
$secondaryButtonTextHoverColor: $accentTextColor;
$secondaryButtonHoverBorderColor: transparent;
$secondaryButtonActiveBg: rgba($accentColor, .68);
$secondaryButtonTextActiveColor: $accentTextColor;
$secondaryButtonActiveBorderColor: transparent;
$secondaryButtonFocusShadow: none;

//$infoButtonBg:2196F3;
$infoButtonBg: $dominantColor;
$infoButtonTextColor: #ffffff;
$infoButtonBorder: 0 none;
$infoButtonHoverBg: rgba(#2196F3, .92);
$infoButtonTextHoverColor: #ffffff;
$infoButtonHoverBorderColor: transparent;
$infoButtonActiveBg: rgba(#2196F3, .68);
$infoButtonTextActiveColor: #ffffff;
$infoButtonActiveBorderColor: transparent;
$infoButtonFocusShadow: none;

$successButtonBg: #689F38;
$successButtonTextColor: #ffffff;
$successButtonBorder: 0 none;
$successButtonHoverBg: rgba(#689F38, .92);
$successButtonTextHoverColor: #ffffff;
$successButtonHoverBorderColor: transparent;
$successButtonActiveBg: rgba(#689F38, .68);
$successButtonTextActiveColor: #ffffff;
$successButtonActiveBorderColor: transparent;
$successButtonFocusShadow: none;

$warningButtonBg: #FBC02D;
$warningButtonTextColor: #212529;
$warningButtonBorder: 0 none;
$warningButtonHoverBg: rgba(#FBC02D, .92);
$warningButtonTextHoverColor: #212529;
$warningButtonHoverBorderColor: transparent;
$warningButtonActiveBg: rgba(#FBC02D, .68);
$warningButtonTextActiveColor: #212529;
$warningButtonActiveBorderColor: transparent;
$warningButtonFocusShadow: none;

$helpButtonBg: #9C27B0;
$helpButtonTextColor: #ffffff;
$helpButtonBorder: 0 none;
$helpButtonHoverBg: rgba(#9C27B0, .92);
$helpButtonTextHoverColor: #ffffff;
$helpButtonHoverBorderColor: transparent;
$helpButtonActiveBg: rgba(#9C27B0, .68);
$helpButtonTextActiveColor: #ffffff;
$helpButtonActiveBorderColor: transparent;
$helpButtonFocusShadow: none;

$dangerButtonBg: #D32F2F;
$dangerButtonTextColor: #ffffff;
$dangerButtonBorder: 0 none;
$dangerButtonHoverBg: rgba(#D32F2F, .92);
$dangerButtonTextHoverColor: #ffffff;
$dangerButtonHoverBorderColor: transparent;
$dangerButtonActiveBg: rgba(#D32F2F, .68);
$dangerButtonTextActiveColor: #ffffff;
$dangerButtonActiveBorderColor: transparent;
$dangerButtonFocusShadow: none;

$linkButtonColor: $primaryColor;
$linkButtonHoverColor: $primaryColor;
$linkButtonTextHoverDecoration: underline;
$linkButtonFocusShadow: none;

//checkbox
$checkboxWidth: 18px;
$checkboxHeight: 18px;
$checkboxBorder: 2px solid #757575;
$checkboxIconFontSize: 14px;
$checkboxActiveBorderColor: $primaryColor;
$checkboxActiveBg: $primaryColor;
$checkboxIconActiveColor: $primaryTextColor;
$checkboxActiveHoverBg: $primaryColor;
$checkboxIconActiveHoverColor: $primaryTextColor;
$checkboxActiveHoverBorderColor: $primaryColor;

//radiobutton
$radiobuttonWidth: 20px;
$radiobuttonHeight: 20px;
$radiobuttonBorder: 2px solid #757575;
$radiobuttonIconSize: 10px;
$radiobuttonActiveBorderColor: $primaryColor;
$radiobuttonActiveBg: $primaryTextColor;
$radiobuttonIconActiveColor: $primaryColor;
$radiobuttonActiveHoverBg: $primaryTextColor;
$radiobuttonIconActiveHoverColor: $primaryColor;
$radiobuttonActiveHoverBorderColor: $primaryColor;

//colorpicker
$colorPickerPreviewWidth: 2rem;
$colorPickerPreviewHeight: 2rem;
$colorPickerBg: #323232;
$colorPickerBorderColor: #191919;
$colorPickerHandleColor: #ffffff;

//togglebutton
$toggleButtonBg: #ffffff;
//$toggleButtonBorder:1px solid rgba(0,0,0,.12);
$toggleButtonBorder: 1px solid $dominantColor;

//$toggleButtonTextColor:$textColor;

$toggleButtonTextColor: $dominantColor;


$toggleButtonIconColor: $textWhiteColor;
$toggleButtonHoverBg: #f6f6f6;
$toggleButtonHoverBorderColor: $dominantColor;
$toggleButtonTextHoverColor: $textColor;
$toggleButtonIconHoverColor: $textWhiteColor;
//$toggleButtonActiveBg:#e0e0e1;
$toggleButtonActiveBg: $dominantBackground;
//$toggleButtonActiveBorderColor:#e0e0e1;
$toggleButtonActiveBorderColor: $dominantColor;
$toggleButtonTextActiveColor: $textWhiteColor;
$toggleButtonIconActiveColor: $textWhiteColor;
$toggleButtonActiveHoverBg: $dominantBackground;
$toggleButtonActiveHoverBorderColor: $dominantColor;
$toggleButtonTextActiveHoverColor: $textWhiteColor;
$toggleButtonIconActiveHoverColor: $textWhiteColor;
;

//inplace
$inplacePadding: $inputPadding;
$inplaceHoverBg: rgba(0, 0, 0, .04);
$inplaceTextHoverColor: $textColor;

//rating
$ratingIconFontSize: 1.143rem;
$ratingCancelIconColor: #B00020;
$ratingCancelIconHoverColor: #B00020;
$ratingStarIconOffColor: $primaryColor;
$ratingStarIconOnColor: $primaryColor;
$ratingStarIconHoverColor: $primaryColor;

//slider
$sliderBg: #c1c1c1;
$sliderBorder: 0 none;
$sliderHorizontalHeight: 2px;
$sliderVerticalWidth: 2px;
$sliderHandleWidth: 20px;
$sliderHandleHeight: 20px;
$sliderHandleBg: $primaryColor;
$sliderHandleBorder: 0 none;
$sliderHandleBorderRadius: 50%;
$sliderHandleHoverBorderColor: 0 none;
$sliderHandleHoverBg: $primaryColor;
$sliderRangeBg: $primaryColor;

//calendar
$calendarTableMargin: .5rem 0;
$calendarPadding: .5rem;
$calendarBg: #ffffff;
$calendarInlineBg: $calendarBg;
$calendarTextColor: $textColor;
$calendarBorder: $inputListBorder;
$calendarOverlayBorder: $inputOverlayBorder;

$calendarHeaderPadding: .5rem;
$calendarHeaderBg: #ffffff;
$calendarInlineHeaderBg: $calendarBg;
$calendarHeaderBorder: $divider;
$calendarHeaderTextColor: $textColor;
$calendarHeaderFontWeight: 500;
$calendarHeaderCellPadding: .5rem;

$calendarCellDatePadding: .5rem;
$calendarCellDateWidth: 2.5rem;
$calendarCellDateHeight: 2.5rem;
$calendarCellDateBorderRadius: 50%;
$calendarCellDateBorder: 1px solid transparent;
$calendarCellDateHoverBg: rgba(0, 0, 0, .04);
$calendarCellDateTodayBg: #ffffff;
$calendarCellDateTodayBorderColor: rgba(0, 0, 0, 12);
$calendarCellDateTodayTextColor: $textColor;

$calendarButtonBarPadding: 1rem 0;
$calendarTimePickerPadding: .5rem;
$calendarTimePickerElementPadding: 0 .5rem;
$calendarTimePickerTimeFontSize: 1.25rem;

$calendarBreakpoint: 769px;
$calendarCellDatePaddingSM: 0;

//input switch
$inputSwitchWidth: 2.75rem;
$inputSwitchHeight: 1rem;
$inputSwitchBorderRadius: .5rem;
$inputSwitchHandleWidth: 1.50rem;
$inputSwitchHandleHeight: 1.50rem;
$inputSwitchHandleBorderRadius: 50%;
$inputSwitchSliderPadding: -1px;
$inputSwitchSliderOffBg: rgba(0, 0, 0, .38);
$inputSwitchHandleOffBg: #ffffff;
$inputSwitchSliderOffHoverBg: rgba(0, 0, 0, .38);
$inputSwitchSliderOnBg: rgba($primaryColor, .5);
$inputSwitchSliderOnHoverBg: rgba($primaryColor, .5);
$inputSwitchHandleOnBg: $primaryColor;

//panel
$panelHeaderBorderColor: #e0e0e0 !default;
$panelHeaderBorder: 1px solid #e0e0e0;
$panelHeaderBg: #ffffff;
$panelHeaderTextColor: $textColor;
$panelHeaderFontWeight: 500;
$panelHeaderPadding: 1rem;
$panelToggleableHeaderPadding: .5rem 1rem;

$panelHeaderHoverBg: rgba(0, 0, 0, .04);
$panelHeaderHoverBorderColor: #e0e0e0;
$panelHeaderTextHoverColor: $textColor;

$panelContentBorderColor: #e0e0e0 !default;
$panelContentBorder: 1px solid #e0e0e0;
$panelContentBg: #ffffff;
$panelContentTextColor: $textColor;
$panelContentPadding: 1rem;

$panelFooterBorder: 1px solid #e0e0e0;
$panelFooterBg: #ffffff;
$panelFooterTextColor: $textColor;
$panelFooterPadding: 1rem 1rem;

//accordion
$accordionSpacing: 0;
$accordionHeaderBorder: 0 none;
$accordionHeaderBg: #ffffff;
$accordionHeaderTextColor: $textColor;
$accordionHeaderFontWeight: 400;
$accordionHeaderPadding: 1.5rem;

$accordionHeaderHoverBg: #f6f6f6;
$accordionHeaderHoverBorderColor: transparent;
$accordionHeaderTextHoverColor: $textColor;

$accordionHeaderActiveBg: #ffffff;
$accordionHeaderActiveBorderColor: transparent;
$accordionHeaderTextActiveColor: $textColor;

$accordionHeaderActiveHoverBg: #ffffff;
$accordionHeaderActiveHoverBorderColor: transparent;
$accordionHeaderTextActiveHoverColor: $textColor;

$accordionContentBorder: 0 none;
$accordionContentBg: #ffffff;
$accordionContentTextColor: $textColor;
$accordionContentPadding: 1rem 1.5rem;

//tabview
$tabviewNavBorder: solid rgba(0, 0, 0, .12);
$tabviewNavBorderWidth: 0 0 1px 0;
$tabviewNavBg: #ffffff;

$tabviewHeaderSpacing: 0;
$tabviewHeaderBorder: none;
$tabviewHeaderBorderWidth: 0 0 0 0;
$tabviewHeaderBorderColor: transparent transparent transparent transparent;
$tabviewHeaderBg: #ffffff;
$tabviewHeaderTextColor: $textSecondaryColor;
$tabviewHeaderFontWeight: 500;
$tabviewHeaderPadding: 1rem 1.5rem;
$tabviewHeaderMargin: 0 0 0 0;

$tabviewHeaderHoverBg: rgba($primaryColor, .04);
$tabviewHeaderHoverBorderColor: transparent;
$tabviewHeaderTextHoverColor: $textSecondaryColor;

$tabviewHeaderActiveBg: #ffffff;
$tabviewHeaderActiveBorderColor: transparent;
$tabviewHeaderTextActiveColor: $primaryColor;

$tabviewContentBorder: 0 none;
$tabviewContentBg: #ffffff;
$tabviewContentTextColor: $textColor;
$tabviewContentPadding: $panelContentPadding;

//upload
$fileUploadProgressBarHeight: 4px;
$fileUploadContentPadding: 2rem 1rem;

//scrollpanel
$scrollPanelTrackBorder: 0 none;
$scrollPanelTrackBg: rgba(0, 0, 0, .12);

//card
$cardBodyPadding: 1rem;
$cardTitleFontSize: 1.5rem;
$cardTitleFontWeight: 700;
$cardSubTitleFontWeight: 400;
$cardSubTitleColor: $textSecondaryColor;
$cardContentPadding: 1rem 0;
$cardFooterPadding: 1rem 0 0 0;
$cardShadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);

//editor
$editorToolbarBg: $panelHeaderBg;
$editorToolbarBorder: $panelHeaderBorder;
$editorToolbarPadding: $panelHeaderPadding;
$editorToolbarIconColor: $textSecondaryColor;
$editorToolbarIconHoverColor: $textColor;
$editorIconActiveColor: $primaryColor;
$editorContentBorder: $panelContentBorder;
$editorContentBg: $panelContentBg;

//paginator
$paginatorBg: #ffffff;
$paginatorTextColor: $textColor;
$paginatorBorder: solid #e4e4e4;
$paginatorBorderWidth: 0;
$paginatorPadding: .5rem 1rem;
$paginatorElementWidth: $buttonIconOnlyWidth;
$paginatorElementHeight: $buttonIconOnlyWidth;
$paginatorElementBg: transparent;
$paginatorElementBorder: 0 none;
$paginatorElementIconColor: $textSecondaryColor;
$paginatorElementHoverBg: rgba(0, 0, 0, .04);
$paginatorElementHoverBorderColor: transparent;
$paginatorElementIconHoverColor: $textSecondaryColor;
$paginatorElementBorderRadius: 50%;
$paginatorElementMargin: .143rem;
$paginatorElementPadding: 0;

//table
$tableHeaderBorder: 1px solid #e4e4e4;
$tableHeaderBorderWidth: 0 0 1px 0;
$tableHeaderBg: #ffffff;
$tableHeaderTextColor: $textColor;
$tableHeaderFontWeight: 500;
$tableHeaderPadding: 1rem 1rem;

$tableHeaderCellPadding: 1rem 1rem;
$tableHeaderCellBg: #ffffff;
$tableHeaderCellTextColor: $textColor;
$tableHeaderCellFontWeight: 500;
$tableHeaderCellBorder: 1px solid #e4e4e4;
$tableHeaderCellBorderWidth: 0 0 1px 0;
$tableHeaderCellHoverBg: rgba(0, 0, 0, .04);
$tableHeaderCellTextHoverColor: $textColor;
$tableHeaderCellIconColor: $textSecondaryColor;
$tableHeaderCellIconHoverColor: $textSecondaryColor;
$tableHeaderCellHighlightBg: #ffffff;
$tableHeaderCellHighlightTextColor: $textColor;
$tableHeaderCellHighlightHoverBg: rgba(0, 0, 0, .04);
$tableHeaderCellHighlightTextHoverColor: $textColor;
$tableSortableColumnBadgeSize: 1.143rem;

$tableBodyRowBg: #ffffff;
$tableBodyRowTextColor: $textColor;
$tableBodyRowEvenBg: rgba(0, 0, 0, .02);
$tableBodyRowHoverBg: rgba(0, 0, 0, .04);
$tableBodyRowTextHoverColor: $textColor;
$tableBodyCellBorder: 1px solid #e4e4e4;
$tableBodyCellBorderWidth: 0 0 1px 0;
$tableBodyCellPadding: 1rem 1rem;

$tableFooterCellPadding: 1rem 1rem;
$tableFooterCellBg: #ffffff;
$tableFooterCellTextColor: $textColor;
$tableFooterCellFontWeight: 500;
$tableFooterCellBorder: 1px solid #e4e4e4;
$tableFooterCellBorderWidth: 0 0 1px 0;
$tableResizerHelperBg: $primaryColor;

$tableFooterBorder: 1px solid #e4e4e4;
$tableFooterBorderWidth: 0 0 1px 0;
$tableFooterBg: #ffffff;
$tableFooterTextColor: $textColor;
$tableFooterFontWeight: 500;
$tableFooterPadding: 1rem 1rem;

$tableCellContentAlignment: left;
$tableTopPaginatorBorderWidth: 0 0 1px 0;
$tableBottomPaginatorBorderWidth: 0 0 1px 0;

$tableScaleSM: 0.5;
$tableScaleLG: 1.25;

//dataview
$dataViewContentPadding: 1rem 0;
$dataViewContentBorder: 0 none;
$dataViewListItemBorder: solid rgba(0, 0, 0, .12);
$dataViewListItemBorderWidth: 0 0 1px 0;

//orderlist, picklist
$orderListBreakpoint: 769px;
$pickListBreakpoint: 769px;

//schedule
$fullCalendarEventBg: $highlightBg;
$fullCalendarEventBorderColor: $highlightBg !default;
$fullCalendarEventBorder: 1px solid $highlightBg;
$fullCalendarEventTextColor: $highlightTextColor;

//tree
$treeNodePadding: .25rem;
$treeNodeContentPadding: .75rem;
$treeNodeChildrenPadding: 0 0 0 1rem;
$treeNodeIconColor: $textSecondaryColor;

//timeline
$timelineVerticalEventContentPadding: 0 1rem !default;
$timelineHorizontalEventContentPadding: 1rem 0 !default;
$timelineEventMarkerWidth: 1rem !default;
$timelineEventMarkerHeight: 1rem !default;
$timelineEventMarkerBorderRadius: 50% !default;
$timelineEventMarkerBorder: 0 none !default;
$timelineEventMarkerBackground: #bdbdbd !default;
$timelineEventConnectorSize: 2px !default;
$timelineEventColor: #bdbdbd !default;

//org chart
$organizationChartConnectorColor: rgba(0, 0, 0, .12);

//message
$messageMargin: 1rem 0;
$messagePadding: 1.25rem 1.5rem;
$messageBorderWidth: 0 0 0 0;
$messageIconFontSize: 1.5rem;
$messageTextFontSize: 1rem;
$messageTextFontWeight: 500;

//inline message
$inlineMessagePadding: $inputPadding;
$inlineMessageMargin: 0;
$inlineMessageIconFontSize: 1rem;
$inlineMessageTextFontSize: 1rem;
$inlineMessageBorderWidth: 1px;

//toast
$toastIconFontSize: 2rem;
$toastMessageTextMargin: 0 0 0 1rem;
$toastMargin: 0 0 1rem 0;
$toastPadding: 1.5rem;
$toastBorderWidth: 0 0 0 0;
$toastShadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
$toastOpacity: .9;
$toastTitleFontWeight: 700;
$toastDetailMargin: $inlineSpacing 0 0 0;

//severities
$infoMessageBg: #B3E5FC;
$infoMessageBorder: solid transparent;
$infoMessageTextColor: #01579B;
$infoMessageIconColor: #01579B;
$successMessageBg: #C8E6C9;
$successMessageBorder: solid transparent;
$successMessageTextColor: #1B5E20;
$successMessageIconColor: #1B5E20;
$warningMessageBg: #FFECB3;
$warningMessageBorder: solid transparent;
$warningMessageTextColor: #7f6003;
$warningMessageIconColor: #7f6003;
$errorMessageBg: #FFCDD2;
$errorMessageBorder: solid transparent;
$errorMessageTextColor: #B71C1C;
$errorMessageIconColor: #B71C1C;

//overlays
$overlayContentBorder: 0 none;
$overlayContentBg: $panelContentBg;
$overlayContainerShadow: 0 11px 15px -7px rgba(0, 0, 0, .2), 0 24px 38px 3px rgba(0, 0, 0, .14), 0 9px 46px 8px rgba(0, 0, 0, .12);

//dialog
$dialogHeaderBg: #ffffff;
$dialogHeaderBorder: 0 none;
$dialogHeaderTextColor: $textColor;
$dialogHeaderFontWeight: 500;
$dialogHeaderFontSize: 1.25rem;
$dialogHeaderPadding: 1.5rem;
$dialogContentPadding: 0 1.5rem 1.5rem 1.5rem;
$dialogFooterBorder: 0 none;
$dialogFooterPadding: 1rem 1.5rem;

//tooltip
$tooltipBg: rgba(97, 97, 97, .9);
$tooltipTextColor: #ffffff;
$tooltipPadding: .5rem;

//steps
$stepsItemBg: transparent;
$stepsItemBorder: 1px solid transparent;
$stepsItemTextColor: $textColor;
$stepsItemNumberWidth: 2rem;
$stepsItemNumberHeight: 2rem;
$stepsItemNumberFontSize: 1.143rem;
$stepsItemNumberColor: $textColor;
$stepsItemNumberBorderRadius: 50%;
$stepsItemActiveFontWeight: 500;

//progressbar
$progressBarHeight: 4px;
$progressBarBorder: 0 none;
$progressBarBg: rgba($primaryColor, .32);
$progressBarValueBg: $primaryColor;

//menu (e.g. menu, menubar, tieredmenu)
$menuWidth: 12.5rem;
$menuBg: #ffffff;
$menuBorder: 1px solid #e5e5e5;
$menuTextColor: $textColor;
$menuitemPadding: 1rem 1rem;
$menuitemBorderRadius: 0;
$menuitemTextColor: $textColor;
$menuitemIconColor: $textSecondaryColor;
$menuitemTextHoverColor: $textColor;
$menuitemIconHoverColor: $textSecondaryColor;
$menuitemHoverBg: rgba(0, 0, 0, .04);
$menuitemTextActiveColor: $textColor;
$menuitemIconActiveColor: $textSecondaryColor;
$menuitemActiveBg: rgba(0, 0, 0, .04);
$menuitemSubmenuIconFontSize: .875rem;
$submenuHeaderMargin: 0;
$submenuHeaderPadding: 1rem;
$submenuHeaderBg: #ffffff;
$submenuHeaderTextColor: $textSecondaryColor;
$submenuHeaderBorderRadius: 0;
$submenuHeaderFontWeight: 400;
$overlayMenuBg: $menuBg;
$overlayMenuBorder: 0 none;
$overlayMenuShadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
$verticalMenuitemMargin: 0;
$verticalMenuPadding: .5rem 0;
$menuSeparatorMargin: .5rem 0;

$breadcrumbPadding: 1rem;
$breadcrumbBg: #ffffff;
$breadcrumbBorder: 1px solid #e5e5e5;
$breadcrumbItemTextColor: $textColor;
$breadcrumbItemIconColor: $textSecondaryColor;
$breadcrumbLastItemTextColor: $textColor;
$breadcrumbLastItemIconColor: $textSecondaryColor;
$breadcrumbSeparatorColor: $textSecondaryColor;

$horizontalMenuPadding: 1rem;
$horizontalMenuBg: transparent;
$horizontalMenuBorder: 1px solid #e5e5e5;
$horizontalMenuTextColor: $textColor;
$horizontalMenuRootMenuitemPadding: 1rem;
$horizontalMenuRootMenuitemBorderRadius: $borderRadius;
$horizontalMenuRootMenuitemTextColor: $textColor;
$horizontalMenuRootMenuitemIconColor: $textSecondaryColor;
$horizontalMenuRootMenuitemTextHoverColor: $textColor;
$horizontalMenuRootMenuitemIconHoverColor: $textSecondaryColor;
$horizontalMenuRootMenuitemHoverBg: rgba(0, 0, 0, .04);
$horizontalMenuRootMenuitemTextActiveColor: $textColor;
$horizontalMenuRootMenuitemIconActiveColor: $textSecondaryColor;
$horizontalMenuRootMenuitemActiveBg: rgba(0, 0, 0, .04);

//badge and tag
$badgeBg: $primaryColor;
$badgeTextColor: $primaryTextColor;
$badgeMinWidth: 1.5rem;
$badgeHeight: 1.5rem;
$badgeFontWeight: 700;
$badgeFontSize: .75rem;

$tagPadding: .25rem .4rem;

//carousel
$carouselIndicatorsPadding: 1rem;
$carouselIndicatorBg: #dcdcdc;
$carouselIndicatorHoverBg: #ececec;
$carouselIndicatorBorderRadius: 0;
$carouselIndicatorWidth: 2rem;
$carouselIndicatorHeight: .5rem;

//galleria
$galleriaMaskBg: rgba(0, 0, 0, 0.9);
$galleriaCloseIconMargin: .5rem;
$galleriaCloseIconFontSize: 2rem;
$galleriaCloseIconBg: transparent;
$galleriaCloseIconColor: rgba(255, 255, 255, .87);
$galleriaCloseIconHoverBg: rgba(255, 255, 255, 0.1);
$galleriaCloseIconHoverColor: rgba(255, 255, 255, .87);
$galleriaCloseIconWidth: 4rem;
$galleriaCloseIconHeight: 4rem;
$galleriaCloseIconBorderRadius: 50%;

$galleriaItemNavigatorBg: transparent;
$galleriaItemNavigatorColor: #f6f6f6;
$galleriaItemNavigatorMargin: 0 .5rem;
$galleriaItemNavigatorFontSize: 2rem;
$galleriaItemNavigatorHoverBg: rgba(255, 255, 255, 0.1);
$galleriaItemNavigatorHoverColor: rgba(255, 255, 255, .87);
$galleriaItemNavigatorWidth: 4rem;
$galleriaItemNavigatorHeight: 4rem;
$galleriaItemNavigatorBorderRadius: 50%;

$galleriaCaptionBg: rgba(0, 0, 0, .5);
$galleriaCaptionTextColor: rgba(255, 255, 255, .87);
$galleriaCaptionPadding: 1rem;

$galleriaIndicatorsPadding: 1rem;
$galleriaIndicatorBg: #dcdcdc;
$galleriaIndicatorHoverBg: #ececec;
$galleriaIndicatorBorderRadius: 50%;
$galleriaIndicatorWidth: 1.25rem;
$galleriaIndicatorHeight: 1.25rem;
$galleriaIndicatorsBgOnItem: rgba(0, 0, 0, .5);
$galleriaIndicatorBgOnItem: rgba(255, 255, 255, .4);
$galleriaIndicatorHoverBgOnItem: rgba(255, 255, 255, .6);

$galleriaThumbnailContainerBg: rgba(0, 0, 0, .9);
$galleriaThumbnailContainerPadding: 1rem .25rem;
$galleriaThumbnailNavigatorBg: transparent;
$galleriaThumbnailNavigatorColor: rgba(255, 255, 255, .87);
$galleriaThumbnailNavigatorHoverBg: rgba(255, 255, 255, 0.1);
$galleriaThumbnailNavigatorHoverColor: rgba(255, 255, 255, .87);
$galleriaThumbnailNavigatorBorderRadius: 50%;
$galleriaThumbnailNavigatorWidth: 2rem;
$galleriaThumbnailNavigatorHeight: 2rem;

//divider
$dividerHorizontalMargin: 1.25rem 0;
$dividerHorizontalPadding: 0 1.25rem;
$dividerVerticalMargin: 0 1.25rem;
$dividerVerticalPadding: 1.25rem 0;
$dividerSize: 1px;
$dividerColor: rgba(0, 0, 0, .12);

//avatar
$avatarBg: rgba(0, 0, 0, .12);
$avatarTextColor: $textColor;

//chip
$chipBg: rgba(0, 0, 0, .12);
$chipTextColor: $textColor;
$chipBorderRadius: 16px;

//scrollTop
$scrollTopBg: $accentColor;
$scrollTopHoverBg: rgba($accentColor, .92);
$scrollTopWidth: 3rem;
$scrollTopHeight: 3rem;
$scrollTopBorderRadius: 50%;
$scrollTopFontSize: 1.5rem;
$scrollTopTextColor: $accentTextColor;

//skeleton
$skeletonBg: rgba(0, 0, 0, .08);
$skeletonAnimationBg: rgba(255, 255, 255, 0.4);

//splitter
$splitterGutterBg: rgba(0, 0, 0, .04);
$splitterGutterHandleBg: rgba(0, 0, 0, .12);

//speeddial
$speedDialButtonWidth: 4rem !default;
$speedDialButtonHeight: 4rem !default;
$speedDialButtonIconFontSize: 2rem !default;
$speedDialActionWidth: 3rem !default;
$speedDialActionHeight: 3rem !default;
$speedDialActionBg: $accentColor !default;
$speedDialActionHoverBg: rgba($accentColor, .92) !default;
$speedDialActionTextColor: #fff !default;
$speedDialActionTextHoverColor: #fff !default;

//dock
$dockActionWidth: 4rem !default;
$dockActionHeight: 4rem !default;
$dockItemPadding: .5rem !default;
$dockCurrentItemMargin: 1.5rem !default;
$dockFirstItemsMargin: 1.3rem !default;
$dockSecondItemsMargin: 0.9rem !default;
$dockBg: rgba(255, 255, 255, .1) !default;
$dockBorder: 1px solid rgba(255, 255, 255, 0.2) !default;
$dockPadding: .5rem .5rem !default;
$dockBorderRadius: .5rem !default;

//image
$imageMaskBg: rgba(0, 0, 0, 0.9) !default;
$imagePreviewToolbarPadding: 1rem !default;
$imagePreviewIndicatorColor: #f8f9fa !default;
$imagePreviewIndicatorBg: rgba(0, 0, 0, 0.5) !default;
$imagePreviewActionIconBg: transparent !default;
$imagePreviewActionIconColor: #f8f9fa !default;
$imagePreviewActionIconHoverBg: rgba(255, 255, 255, 0.1) !default;
$imagePreviewActionIconHoverColor: #f8f9fa !default;
$imagePreviewActionIconWidth: 3rem !default;
$imagePreviewActionIconHeight: 3rem !default;
$imagePreviewActionIconFontSize: 1.5rem !default;
$imagePreviewActionIconBorderRadius: 50% !default;

:root {
  --orange-Color: #{$orangeColor};
  --blu-ColorA: #{$bluColorA};
  --blu-ColorB: #{$bluColorB};
  --lightBlu-ColorA: #{$lightBluColorA};
  --lightBlu-ColorB: #{$lightBluColorB};
  --black-Color: #{$black};
  --white-Color: #{$white};
  --surface-a: #ffffff;
  --surface-b: #fafafa;
  --surface-c: rgba(0, 0, 0, .04);
  --surface-d: rgba(0, 0, 0, .12);
  --surface-e: #ffffff;
  --surface-f: #ffffff;
  --text-color: #{$textColor};
  --text-color-secondary: #{$textSecondaryColor};
  --primary-color: #{$primaryColor};
  --primary-color-text: #{$primaryTextColor};
  --font-family: #{$fontFamily};
  --surface-0: #ffffff;
  --surface-50: #FAFAFA;
  --surface-100: #F5F5F5;
  --surface-200: #EEEEEE;
  --surface-300: #E0E0E0;
  --surface-400: #BDBDBD;
  --surface-500: #9E9E9E;
  --surface-600: #757575;
  --surface-700: #616161;
  --surface-800: #424242;
  --surface-900: #212121;
  --gray-50: #FAFAFA;
  --gray-100: #F5F5F5;
  --gray-200: #EEEEEE;
  --gray-300: #E0E0E0;
  --gray-400: #BDBDBD;
  --gray-500: #9E9E9E;
  --gray-600: #757575;
  --gray-700: #616161;
  --gray-800: #424242;
  --gray-900: #212121;
  --content-padding: #{$panelContentPadding};
  --inline-spacing: #{$inlineSpacing};
  --border-radius: #{$borderRadius};
  --surface-ground: #fafafa;
  --surface-section: #ffffff;
  --surface-card: #ffffff;
  --surface-overlay: #ffffff;
  --surface-border: #rgba(0, 0, 0, .12);
  --surface-hover: rgba(0, 0, 0, .04);
  --maskbg: #{$maskBg};
  --focus-ring: #{$focusShadow};
}
