


@import "node_modules/primeng/resources/primeng.min";
@import "node_modules/primeflex/primeflex.scss";
@import "node_modules/primeicons/primeicons";
@import "node_modules/prismjs/themes/prism-coy";
@import "node_modules/@fullcalendar/daygrid/main.min";
@import "node_modules/@fullcalendar/timegrid/main.min";
@import "assets/theme/blue/theme-light.scss";
//  @import "assets/layout/css/layout-light.scss";

@font-face {
    font-family: Assistant;
    src: url(assets/fonts/Assistant/static/Assistant-Regular.ttf) format("opentype");
}
@font-face {
  font-family: Heebo;
  src: url(assets/fonts/heebo/Heebo-Regular.ttf) format("opentype");
}


// .fw-900 {
//     font-family: Heebo-bold;
// }

BODY {
    direction: rtl;
    font-weight: 400;
    background: 0 0;
    // font-family: Heebo, tahoma, arial, sans-serif;
    background-color: #fff;
    font-size: 100%;
}

:root {
    --orangeColor: #ff6700;
    --bluColorA: #030f6d;
    --bluColorB: #2ca5db;
    --lightBluColorA: #cae8fc;
    --lightBluColorB: #eff9fe;
    --black: #000000;
    --white: #ffffff;
    --gray:gray;
    --border-radius:1.2rem
}
.gray{
    color:var(--gray);
}
.primary {
    color: var(--bluColorA);
}

.secondary {
    color: var(--bluColorB);
}
.secondary:hover {
    color: var(--bluColorB) !important;
}
.primaryFill {
    color: var(--white);
    background-color: var(--bluColorA);
}

.secondaryFill {
    color: var(--white);
    background-color: var(--bluColorB);
}

$dominantColor: $dominantColor;
$dominantColor2: $dominantColor2;

.p-button .p-button-icon-left {
    margin-left: 0.5rem !important;
    margin-right: 0 !important;
}

.p-button .p-button-icon-right {
    margin-right: 0.5rem !important;
    margin-left: 0 !important;
}

.p-button.p-button-icon-only .p-button-icon-left,
.p-button.p-button-icon-only .p-button-icon-right {
    margin: 0 !important;
}

.card.w-35rem {
    width: 35rem;
}

//==========
//select-button
.p-buttonset .p-button:first-of-type {
    border-radius: 3px !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-right: 1px solid $dominantColor !important;

    .p-highlight {
        border-right: none !important;
    }
}

.p-buttonset .p-button:last-of-type {
    border-radius: 3px !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    margin-right: -1px;
}

//ag grid
.ag-header-viewport {
    background-color: $dominantColor;
    color: $textWhiteColor;
    border-bottom: none;
}

.ag-theme-alpine .ag-header-row {
    color: $textWhiteColor;
}

.ag-theme-alpine .ag-header-cell-resize::after {
    background-color: $textWhiteColor;
}

.ag-theme-alpine .ag-row-odd .ag-cell {
    background-color: #a7def817 !important;
    // background-color: var(--ag-odd-row-background-color, #fcfcfc);
}

.ag-theme-alpine .ag-row-selected .ag-cell {
    background-color: #a7dff8 !important;
}

.ag-theme-alpine .ag-row-hover .ag-cell {
    background-color: #a7def887 !important;
}

//============
.rtl .p-float-label > label {
    :not([labelStyle]) {
        font-size: 1rem !important;
    }

    right: 0.75rem;
    margin-left: unset;
    // margin-top: -0.1rem;
    left: unset;
    white-space: nowrap;
}

//.p-float-label > label {
// left: 0.75rem;
// }
//==========

input,
select,
textarea {
    // font-family: Heebo, tahoma, arial, sans-serif;
    margin: 0;
    padding: 0;
    color: #154ba3;
}

button,
a,
a *,
button *,
select,
select * {
    cursor: pointer !important;
    pointer-events: auto;
}

.bg-white {
    background-color: white !important;
}

.bg {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 13px #00000029 !important;
    border-radius: 5px;
}

ul,
li {
    list-style: none;
}

.kesher-button.p-button-raised.p-button-text.p-button-plain {
    border: 5px solid red !important;
    background: red !important;
}

.float-left {
    float: left;
}

.dispaly-inline {
    display: inline;
}

.margin-30 {
    margin: 30px;
}

.gap-1\/2 {
    gap: 0.3rem;
    transition: 0.2s all ease-in-out;
}

.gap-1 {
    gap: 0.6rem;
}

.gap-2 {
    gap: 2rem;
}

.gap-4 {
    gap: 4rem;
}

.hover-move:hover {
    gap: 0.8rem !important;
}

.display-flex {
    display: flex;
}

.mr-auto {
    margin-right: auto;
}

.mr-1 {
    margin-right: 1rem;
}

.mr-2 {
    margin-right: 2rem;
}

.ml-0\.5 {
    margin-left: 0.5rem;
}

.ml-space {
    margin-left: 2rem;
}

.-mt-1 {
    margin-top: -0.5rem;
}

.-mt-2 {
    margin-top: -1rem;
}

.-mt-4 {
    margin-top: -4.3rem;
}

.mt-1 {
    margin-top: 5rem;
}

.mt-20 {
    margin-top: 8rem;
}

.my-auto {
    margin-top: auto;
    margin-bottom: auto;
}

.mb-auto {
    margin-bottom: auto;
}

.my-1 {
    margin-top: 0.6rem;
    margin-bottom: 0.6rem;
}

.my-2 {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.my-3 {
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.mx-1 {
    margin-left: 0.1em;
    margin-right: 0.1em;
}

.mx-2 {
    margin-left: 0.2em;
    margin-right: 0.5rem;
}

.py-2 {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.py-3 {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.py-4 {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.px-2 {
    padding-right: 0.4rem;
    padding-left: 0.4rem;
}

.p {
    padding: 0.4rem;
}

.p-2 {
    padding: 3rem;
}
.border {
    border-radius: 2rem;
}

// .p-overlaypanel {
//     top: auto !important;
//     left: auto !important;
//     position: fixed;
// }

.w-full {
    width: 100%;
}

.w-full\! {
    width: 100% !important;
}

.w-3\/4 {
    width: 85%;
}

.w-1\/5 {
    width: 15%;
}

.w-4\/5 {
    width: 90%;
}

.w-1\/3 {
    width: 33%;
}

.w-1\/2 {
    width: 60%;
}

.w-1\/4 {
    width: 25%;
}

.text-red {
    color: rgb(248 113 113);
}

.text-blue {
    color: #2196f3;
}

.fs-medium {
    font-size: 1.25rem;
}

payment-pages-popup,
custom-date-filter {
    position: fixed;
    z-index: 2;
}

.ag-row {
    z-index: 0;
}

.ag-row.ag-row-focus {
    z-index: 1;
}

.p-disabled:hover {
    pointer-events: auto;
}


.ag-theme-alpine input[class^="ag-"][type="text"]:disabled::before,
.ag-input-field.ag-disabled::before {
    /** For arrow sign: */
    // content: "\e902";
    // font-family: 'primeicons';
    // width: 100px;
    // height: 20px;

    /** For filter  sign: */
    content: "\f114";
    // font-family: "agGridAlpine";
    color: rgb(73, 64, 64);
    cursor: pointer;
    font-weight: 500;
    font-size: 17px;
    position: absolute;
    margin-right: 35%;
}

.ag-input-field.ag-disabled > .ag-input-wrapper {
    display: none;
}

.underline {
    text-decoration: underline;
}

.hover-underline:hover {
    color: rgb(0, 0, 253);
    text-decoration: underline;
}

.h-inherit {
    height: inherit;
}

.text-white {
    color: white;
}

.text-left {
    text-align: left;
}

.cursor-disabled {
    cursor: not-allowed;
}

.flex {
    display: flex;
}

.contents {
    display: contents;
}

.fixed {
    position: fixed;
}

.fade {
    opacity: 1;
    transition: all 0.2s ease;

    &:hover {
        opacity: 0.6;
    }
}

.solid-fade:hover {
    background-color: #f8f8f8 !important;
}

.page-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

p-galleria {
    .pi-times::before,
    .pi-chevron-right:before {
        color: white !important;
    }

    .pi-chevron-left:before {
        color: white !important;
    }
}

.p-autocomplete-dd .p-autocomplete-dropdown {
    border-top-left-radius: 3px !important;
    border-bottom-left-radius: 3px !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.p-autocomplete-dd .p-autocomplete-input {
    border-top-right-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.p-field {
    margin-bottom: 0.2rem;
}

.p-dialog-content {
    height: 100% !important;
}

.p-scrollpanel-bar {
    background-color: var(--layer-2);
    border-radius: 0;
    opacity: 1;
    transition: background-color 0.2s;
}

.p-timepicker {
    direction: initial !important;
}

.rtl {
    direction: rtl;

    .p-offset-1 {
        margin-right: 8.33333333%;
        margin-left: auto !important;
    }
}

.ltr {
    direction: ltr !important;
}

.p-autocomplete {
    > button {
        background: transparent;
        position: absolute;
        left: 0px;

        &:hover {
            background-color: transparent !important;
            opacity: 0.6;
            margin-top: 7px;
            transition: margin-top 0.2s ease-in-out;
        }
    }
}

.ag-theme-alpine .ag-cell.red-background {
    background: #f21000b6 !important;
    opacity: 0.8;
}

.ag-theme-alpine .ag-cell.red-text {
    color: red !important;
}

.ag-theme-alpine .ag-cell.green-background {
    background: #99ce87 !important;
    opacity: 0.8;
}

.ag-theme-alpine .ag-cell.red-border {
    border-color: #b00020 !important;
    border-width: 2px !important;
    border-style: solid !important;
    border-radius: 0.4rem !important;
}

// .form-group {
//     margin-bottom: 25px;
// }

// .p-float-label {
//     max-height: 44px !important;
//     padding-top: 0 !important;
//     padding-bottom: 0 !important;
// }

// ::ng-deep .p-dropdown {
//     max-height: 44px !important;
// }

// ::ng-deep .p-inputtext {
//     max-height: 44px !important;
// }
.p-datepicker .p-datepicker-header .p-datepicker-prev {
    order: 3;
}

.p-datepicker .p-datepicker-header .p-datepicker-next {
    order: 2;
}

.comment-fz {
    font-size: 12px;
}

.display-block {
    display: block;
}

div[dir="rtl"] .p-breadcrumb-chevron {
    transform: rotate(180deg);
}

div[dir="rtl"] .p-breadcrumb span:before {
    padding-left: 0.5rem;
}

.p-breadcrumb {
    border: none;
}

.p-carousel-prev-icon.pi-chevron-left::before {
    content: "\e901" !important;
    color: #fff;
}

.p-carousel-prev-icon.pi-chevron-right::before {
    content: "\e900" !important;
    color: #fff;
}

.ag-filter-select,
.ag-filter-condition,
.ag-filter-body:last-child {
    display: none !important;
}

.hover\:rotate {
    transition: 0.2s all ease-in-out;

    &:hover {
        transform: rotate(90deg);
    }
}

.inline-table {
    display: inline-table;
}

.kesherPayment_logo {
    position: fixed;
    z-index: 100;
    top: -80px;
    width: 240px;
    height: 200px;
    padding-top: 64px;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0px 0px 45px rgb(17 18 19 / 50%);
    overflow: hidden;
}

.kesherPayment_logo img {
    width: 46%;
}

.floatLabelPlaceholder {
    top: 0.25rem !important;
    margin-top: 0;
    background: transparent;
    // font-size: 12px !important;
    padding: 2px 4px !important;
    margin-right: 0 !important;
    margin-top: 0 !important;
}

.errorText {
    color: red;
    font-size: 18px;
    text-align: center;
    padding: 13px;
}
.successText {
    color: green;
    font-size: 18px;
    text-align: center;
    padding: 13px;
}
.ag-theme-alpine {
    font-family: Assistant, tahoma, arial, sans-serif;
    // font-family: "Heebo"
}

.ag-theme-alpine .ag-header-cell.ag-header-cell-moving,
.ag-theme-alpine .ag-header-group-cell.ag-header-cell-moving {
    background-color: #2ca5db63;
}

.ag-theme-alpine .ag-pinned-right-header .ag-header-cell-resize::after {
    left: calc(50% - 1px);
}

.p-float-label > label {
    top: -0.5rem !important;
    background-color: #ffffff;
    padding: 2px 4px;
    margin-left: -4px;
    margin-top: 0;
}

.p-float-label input:focus ~ label,
.p-float-label input.p-filled ~ label,
.p-float-label textarea:focus ~ label,
.p-float-label textarea.p-filled ~ label,
.p-float-label .p-inputwrapper-focus ~ label,
.p-float-label .p-inputwrapper-filled ~ label {
    font-size: 0.875rem;
}

.ql-editor li:not(.ql-direction-rtl)::before {
    margin-left: 0 !important;
}

.excel-img {
    background-image: url(assets/images/icons/excel.png);
    height: 30px;
    display: block;
    background-repeat: no-repeat;
}

ag-grid-dynamic {
    height: inherit;
}

.p-inputtext:disabled,
.p-dropdown.p-disabled {
    opacity: 1;
    border-color: rgba(0, 0, 0, 0.3) !important;
}

.p-multiselect {
    min-width: 118px;
}

p-checkbox {
    vertical-align: middle !important;
}

.p-checkbox-label {
    margin-right: 0.5rem !important;
}

.userIcon {
    background-image: url("assets/images/icons/account.png");
    height: 20px !important;
    width: 20px;
    display: inline-block !important;
    background-repeat: round;
}

.moneyIcon {
    background-image: url("assets/images/icons/money.png");
    height: 20px !important;
    width: 34px;
    display: inline-block !important;
    background-repeat: round;
}

.basketIcon {
    background-image: url("assets/images/icons/basket.png");
    height: 20px !important;
    width: 20px;
    display: inline-block !important;
    background-repeat: round;
}

.microphoneIcon {
    background-image: url("assets/images/icons/microphone.png");
    height: 20px !important;
    width: 20px;
    display: inline-block !important;
    background-repeat: round;
}

.customerIcon {
    background-image: url("assets/images/icons/customers_white.png");
    height: 15px !important;
    width: 15px;
    display: inline-block !important;
    background-repeat: round;
}

.swipeCardIcon {
    background-image: url("assets/images/icons/swipeCard.png");
    height: 20px !important;
    width: 20px;
    display: inline-block !important;
    background-repeat: round;
}

.successIcon {
    background-image: url("assets/images/icons/success.png");
    height: 150px;
    width: 150px;
    display: inline-block !important;
    background-repeat: round;
}

.hourglass {
    background-image: url("assets/images/icons/hourglass.png");
    height: 150px;
    width: 100px;
    display: inline-block !important;
    background-repeat: round;
}

.notActive {
    background-image: url("assets/images/icons/notActive.png");
    height: 150px;
    width: 100px;
    display: inherit !important;
    background-repeat: round;
}

.addIcon {
    background-image: url("assets/images/icons/add.png");
    height: 17px !important;
    width: 18px;
    display: inherit !important;
    background-repeat: round;
}

.definitions {
    background-image: url("assets/images/icons/definitions.png");
    height: 17px !important;
    width: 18px;
    display: inherit !important;
    background-repeat: round;
}

@media (max-width: 600px) {
    .successIcon {
        padding-top: 110px !important;
    }
}

.lanier-background {
    background: $dominantBackground;
}

.editIcon {
    background-image: url("/assets/images/icons/edit.png");
    height: 18px !important;
    display: inherit !important;
    background-repeat: round;
    border-radius: 4px !important;
    width: 18px;
}

.iconImage {
    background-image: var(--iconimg);
    height: 1rem !important;
    display: block !important;
    background-repeat: round;
    width: 1rem;
}

.deleteIcon {
    background-image: url("/assets/images/delete.png");
    height: 18px !important;
    display: block !important;
    background-repeat: round;
    border-radius: 4px !important;
    width: 18px;
}

.editingCommitmentIcon {
    background-image: url("assets/images/icons/EditingCommitment.png");
    height: 27px !important;
    display: block !important;
    background-repeat: round;
    border-radius: 4px !important;
    width: 2.5rem;
}

.generalSettingsIcon {
    background-image: url("/assets/images/icons/general-settings.png");
    height: 20px !important;
    display: block !important;
    background-repeat: round;
    border-radius: 4px !important;
    width: 20px;
}

.actionsAfterSendingSettingsIcon {
    background-image: url("/assets/images/icons/settings2.png");
    height: 20px !important;
    display: block !important;
    background-repeat: round;
    border-radius: 4px !important;
    width: 20px;
}

.designSettingsIcon {
    background-image: url("/assets/images/icons/design.png");
    height: 20px !important;
    display: block !important;
    background-repeat: round;
    border-radius: 4px !important;
    width: 20px;
}

.concatenationIcon {
    background-image: url("/assets/images/icons/concatenation.png");
    height: 20px !important;
    display: block !important;
    background-repeat: round;
    border-radius: 4px !important;
    width: 20px;
}

.groups {
    background-image: url("/assets/images/icons/groups.png") !important;
    height: 20px !important;
    display: block !important;
    background-repeat: round;
    border-radius: 4px !important;
    width: 20px;
}

.personalIcon {
    background-image: url("/assets/images/icons/personal.png");
    height: 20px !important;
    display: block !important;
    background-repeat: round;
    border-radius: 4px !important;
    width: 20px;
}

.personIcon {
    background-image: url("/assets/images/icons/person.png");
    height: 21px !important;
    display: block !important;
    background-repeat: round;
    border-radius: 4px !important;
    width: 19px;
}

.creditIcon {
    background-image: url("/assets/images/icons/credit.png");
    height: 14px !important;
    display: block !important;
    background-repeat: round;
    border-radius: 4px !important;
    width: 20px;
}

.productSettingsIcon {
    background-image: url("/assets/images/icons/basket2.png");
    height: 20px !important;
    display: block !important;
    background-repeat: round;
    border-radius: 4px !important;
    width: 20px;
}

.definitionsIcon {
    background-image: url("/assets/images/icons/definitions.png");
    height: 20px !important;
    display: block !important;
    background-repeat: round;
    border-radius: 4px !important;
    width: 20px;
}

.cameraIcon {
    background-image: url("/assets/images/icons/camera.png");
    height: 20px !important;
    display: block !important;
    background-repeat: round;
    border-radius: 4px !important;
    width: 20px;
}

.addCustomer {
    background-image: url("assets/images/icons/addCustomer.png ");
    height: 18px !important;
    display: inherit !important;
    background-repeat: round;
    border-radius: 4px !important;
    width: 18px;
}

.button1 {
    border-radius: 4px !important;
    box-shadow: 0 0 10px 4px #80808033 !important;
    height: 2.75rem;
}

.linkIcon {
    background-image: url("/assets/images/link.png");
    height: 20px !important;
    display: block !important;
    background-repeat: round;
    border-radius: 4px !important;
    width: 2rem;
}

.box-shadow-0-0-10-4 {
    box-shadow: 0 0 10px 4px #80808033 !important;
}

info-panel {
    width: 30%;
}

info-panel:not(.enabled) + info-panel:not(.first-on-row) {
    margin-right: 0.3rem;
    padding-right: 0.5rem;
    display: flex;
    gap: 0.7rem;

    &:before {
        content: "|";
        height: 80%;
        width: 2px;
        color: #d3c8c8;
    }
}

info-panel.enabled {
    width: 0px !important;
    opacity: 0;
}

.imgWhite {
    filter: brightness(0) invert(1);
}

.black-color {
    color: black;
}

.lanier-background-button {
    @extend .lanier-background;
}

.white-background-button {
    border: 1px solid #0f74b5 !important;
    color: #0f74b5 !important;
    background: white !important;
}

.white-background-button:hover {
    color: #0f74b5 !important;
}

.devide-color {
    color: #dcdada;
}

.ag-side-button.ag-selected::before {
    font-family: "primeicons";
    content: "\e90b";
    margin-right: 6px;
    background: #2196f3;
    position: absolute;
    padding: 4px 4px;
    border-radius: 9999px;
    color: white;
    font-size: x-small;
    margin-top: 2px;
    text-align: center;
    box-shadow:
        0px 1px 8px rgb(0 0 0 / 8%),
        0px 3px 4px rgb(0 0 0 / 10%),
        0px 1px 4px -1px rgb(0 0 0 / 10%) !important;
    cursor: pointer;
}

.obligationCardWidth,
.advertismentCardWidth {
    width: 55em !important;
}
.uploaFileCardWidth {
    width: 55em !important;
}
.addExistProductWidth {
    width: 80% !important;
}

@media (max-width: 1550px) {
    .obligationCardWidth,
    .advertismentCardWidth,
    .addExistProductWidth{
        width: 98% !important;
    }
}
@media (max-width: 1150px) {
   
    .uploaFileCardWidth {
        width: 98% !important;
    }
}
.p-datepicker .p-yearpicker {
    margin: 0.5rem 0;
}

.p-datepicker .p-yearpicker .p-yearpicker-year {
    padding: 0.5rem;
    transition: box-shadow 0.2s;
    border-radius: 6px;
}

.p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
    color: #1d4ed8;
    background: #eff6ff;
}

.p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):hover {
    background: rgb(73 73 76 / 3%);
}

.p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px #93cbf9;
}

.active-img {
    height: 1.3rem;
}

.fontColor {
    color: #000000 !important;
}

.contentFontSize {
    width: 100px;
    height: 20px;
    font: normal normal normal 20px/24px;
}

.infoLabel {
    color: #86888c !important;
}

.infoLabel span {
    color: #000000 !important;
}

numeric-cell {
    width: 100% !important;
}

.titleWithImg {
    @extend.gap-1;
    @extend.flex;
    color: #021f4e;
    font-size: 1.25rem;
}

.titleWithImg p {
    @extend.font-bold;
}

.title {
    @extend.gap-1;
    @extend.flex;
    color: #021f4e;
}

title p {
    @extend.font-bold;
}

.groupIcon {
    background-image: url("/assets/images/icons/group.png");
    height: 1rem !important;
    display: block !important;
    background-repeat: round;
    border-radius: 4px !important;
    width: 1rem;
}

.p-card .p-card-content,
.p-card .p-card-body,
.p-tabview .p-tabview-panels {
    padding: 0px !important;
}

.p-tabview-panels .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    background-color: #eff9fe;
}

.p-tabview div .p-tabview-nav-container .p-tabview-panels .p-tabpanel div .p-tabview div div div ul li a {
    padding: 0.5rem !important;
}

.p-tabview p-component .p-tabview-nav-container .p-tabview-nav-content .ul {
    background-color: #eff9fe;
}

// p-card div div{
//     height: 100%!important;
// }
// .p-card .p-card-body,
// .p-card .p-card-content {
//     height: 100% !important;
// }

.p-tabview-panels > .ob-wrapper > .p-tabview-panel {
    padding: 0 !important;
}

.p-tabview .p-tabview-nav {
    background-color: #eff8fe;
}

.p-tabview-nav-content .p-tabview-nav {
    background-color: #cae8fc;
}

.tab .p-tabview-nav-content .p-tabview-nav {
    background-color: #eff9fe !important;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background-color: #2ca5db !important;
    border-radius: 5px;
    color: white;
}

.p-tabview .tab .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background-color: #eff9fe !important;
    color: #0f74b5;
}

// .p-tabview .tab .p-tabview-nav li.p-highlight .p-tabview-nav-link .p-tabview-left-icon {
//     filter: brightness(1) invert(0);
// }
.p-tabview .tab .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    // background-color: #cae8fc!important;
    // background-color: white!important;
    background-color: #2ca5db !important;
    background-position-x: left;
    color: white;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link .p-tabview-left-icon,
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link .p-tabview-right-icon {
    filter: brightness(0) invert(1);
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
    background-color: #cae8fc;
}

.p-tabview-panels,
.p-tabview-panel {
    height: 100%;
    padding: 1rem !important;
}

.p-tabview-nav-content .p-tabview-nav .p-tabview-ink-bar {
    height: 0 !important;
}

.tab .p-tabview-nav-content .p-tabview-nav .p-tabview-ink-bar {
    height: 0.125rem !important;
}

.grecaptcha-badge {
    visibility: hidden;
}

.color-blue {
    color: #0f74b5 !important;
}

@media screen and (min-width: 768px) {
    .md\:gap-4 {
        gap: 4rem;
    }

    .md\:gap-2 {
        gap: 2rem;
    }

    .md\:display-block {
        display: block !important;
    }
}

@media screen and (min-width: 992px) {
    .lg\:display-block {
        display: block !important;
    }
}

@media screen and (min-width: 750px) and (max-width: 800px) {
    html {
        font-size: 21px;
    }
}

/* width */
// ::-webkit-scrollbar {
//     width: 8px;
//   }

//   /* Track */
//   ::-webkit-scrollbar-track {
//     box-shadow: inset 0 0 5px white;
//     border-radius: 3px;
//   }

//   /* Handle */
//   ::-webkit-scrollbar-thumb {
//     background: #c0c0c0;
//     border-radius: 3px;
//   }

//   /* Handle on hover */
//   ::-webkit-scrollbar-thumb:hover {
//     background: #c0c0c0;
//   }
.max-height-600 {
    max-height: 600px;
}

.imgWhite {
    filter: brightness(0) invert(1);
}
.max-width-panel{
    max-width: 100%;
}


.p-sidebar .p-sidebar-content {
    height: 100% 
}
.pointer {
    cursor: pointer !important;
}
.p-dialog {
    max-width: 98%;
}
/*
.rtl .field-checkbox > label, .field-radiobutton > label{
    margin-left: 0rem;
    margin-right: 0.5rem;

}*/
