@media (max-width: $mobileBreakpoint) {
    .layout-topbar {
        height: auto;
        display: flex;
        flex-direction: column;

        .layout-topbar-left {
            height: 4rem;
            width: 100%;
            justify-content: flex-start;

            .layout-topbar-mobile-button {
                display: flex;
            }
        }

        .layout-topbar-right {
            display: none;
            flex-direction: column-reverse;
            padding: 0;
            @include material-overlay-animation;

            &.layout-topbar-mobile-active {
                display: flex;
            }

            .layout-topbar-actions-left {
                height: 4rem;
                display: flex;
                justify-content: space-between;

                .layout-megamenu {
                    .p-megamenu-panel {
                        position: fixed;
                        left: 1rem;
                        right: 1rem;
                        max-height: calc(100vh - 168px);
                        overflow-y: scroll;

                        .p-megamenu-grid {
                            flex-wrap: wrap;

                            > [class*="p-megamenu-col-"] {
                                flex: 1 1 auto;
                                width: auto;
                            }

                            .p-megamenu-submenu {
                                width: auto;
                                min-width: 12.5rem;
                            }
                        }
                    }
                }
            }

            .layout-topbar-actions-right {
                height: 4rem;
                width: 100%;

                .layout-topbar-items {
                    width: 100%;
                    justify-content: space-between;

                    .layout-topbar-item {
                        .layout-topbar-action-panel {
                            position: fixed;
                            top: 8rem;
                            left: 1em;
                            right: 1em;
                        }
                    }
                }
            }
        }
    }
    .mobile-fast-payment-button {
        display: flex;
    }
    .fast-payment-button{
        display: none;
    }
}

@media (min-width: $mobileBreakpoint + 1) {
    .layout-menu-horizontal,
    .layout-menu-slim {
        .layout-topbar {
            .layout-topbar-left {
                .layout-menu-button {
                    display: none;
                }
            }
        }
    }
}
