/* Add your variable customizations of theme here */
$float:left;
:root {
    --float:left;
}


@mixin padding($value) {
    [dir="ltr"] & {
        padding-left: $value;
      }
    
    [dir="rtl"] & {
        margin-right: $value;
      }
  }
 