
// @import "../../../../theme/blue/theme-light.scss";

.layout-menu-light {
    $menuBgColor:#FDFEFF;

    $rootMenuTextColor:#0F74B5;

    $menuItemTextColor:#0F74B5;
    $menuItemHoverBgColor:rgba(0,0,0,.04);
    $activeMenuItemTextColor:white;
    $activeMenuItemBgColor:#2CA5DB 0% 0% no-repeat padding-box;
    $inlineMenuBorderColor: #e4e4e4;

    @import '../_menu_exports';
    @import '../_menu_theme';
}
