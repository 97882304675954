.p-datatable-products .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media (max-width: $mobileBreakpoint) {
    .p-datatable {
        &.p-datatable-products {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr {
                border-bottom: 1px solid var(--surface-d);

                > td {
                    text-align: left;
                    display: flex;
                    border: 0 none !important;
                    width: 100% !important;
                    border: 0 none;
                    align-items: center;

                    .p-column-title {
                        min-width: 30%;
                        display: block;
                        font-weight: bold;
                    }
                }
            }
        }
    }

    .layout-rtl {
        .p-datatable {
            &.p-datatable-products {
                .p-datatable-tbody > tr {
                    > td {
                        text-align: right;
                        float: right;
                        clear: right;

                        .p-column-title {
                            margin: -.4rem -.4rem -.4rem 1rem;
                        }
                    }
                }
            }
        }
    }
}
